import React from 'react';
import Layout from './src/components/Layout';
import { InventoryOrderProvider } from './src/components/InventoryOrderContext';
import './src/styles/index.css';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  return <InventoryOrderProvider>{element}</InventoryOrderProvider>;
}