import React from 'react';
import styled from 'styled-components';
//import stripes from '../assets/images/stripes.svg';
import LogoImg from '../assets/images/logo.png';

const LogoStyles = styled.div`
  /* This value controls the entire size of the logo*/
  font-size: 6px;
  font-size: clamp(1px, 0.65vw, 8px);
  width: 30em;
  height: 30em;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  margin: 0;
  --borderSize: 1em;

  .logo-item-img {
    max-width: 80px;
    margin: 0 auto;
  }

  background-size: 150em;
  border: var(--borderSize) solid white;
  display: flex;
  .inner {
    margin: var(--borderSize);
    flex: 1;
    background: white;
    display: grid;
    grid-template-rows: 20% 1fr 1fr;
    align-content: bottom;
  }
  .est {
    font-size: 1.5em;
    align-self: center;
  }
  h1 {
    display: grid;
    grid-template-rows: 8fr 2fr;
    align-items: end;
    margin: 0;
    grid-row: 2 / span 2;
    grid-gap: 2em;
    transform: translateY(-0.7em);
  }

  .slices {
    font-size: 1.2em;
    letter-spacing: 0.2em;
    transform: translateY(-0.15em);
  }
  .slicks {
    transform: scale(1.4);
    display: block;
    text-shadow: 0.18em 0.18em 0 rgba(0, 0, 0, 0.05);
    perspective: 100px;
  }
  .letter {
    font-size: 2em;
    color: #000;
    --scale: 1;
    --rotate: -10deg;
    --translateX: 0;
    --translateY: 0;
    --rotateX: 0deg;
    transform: scale(var(--scale)) rotate(var(--rotate))
      translateX(var(--translateX)) translateY(var(--translateY))
      rotateX(var(--rotateX));
    display: inline-block;
    line-height: 1;
    transition: transform 0.3s;

  }
`;

export default function Logo() {
  return (
    <LogoStyles className="logo">
      <div className="inner">
        <img className="logo-item-img" src={LogoImg} alt="Sigma Pest Control logo" />
        <h1>
          <span className="slicks">
            <span className="letter S">Sigma Pest <br></br>Control</span>
          </span>
          <span className="slices">Inventory</span>
        </h1>
      </div>
    </LogoStyles>
  );
}