import React, { useState } from 'react';

// Create a order context
const InventoryOrderContext = React.createContext();

export function InventoryOrderProvider({ children }) {
  // we need to stick state in here
  const [inventoryOrder, setInventoryOrder] = useState([]);
  return (
    <InventoryOrderContext.Provider value={[inventoryOrder, setInventoryOrder]}>
      {children}
    </InventoryOrderContext.Provider>
  );
}

export default InventoryOrderContext;